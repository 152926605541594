.song-lyrics {
    width: 100%;
    text-align: center;
    background-color: black;
    color: white;
    padding: 30px 5px;
    font-size: 2rem;
}

.song-lyrics-svg {
    width: 100%;
    height: 70vh;
    text-align: center;
    background-color: black;
    padding: 5px 5px;
}

.song-lyrics-svg-fullscreen {
    width: 100%;
    height: 100vh;
    text-align: center;
    background-color: black;
    padding: 5px 5px;
}
